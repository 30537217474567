import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import Icons from "../logo"
import Btn from '../ui/btn'
import Footer from "../Footer"
import { AnimatedTooltip } from "../ui/animated-tooltip";
import Img17 from "../../assets/img17.jpg"
import TextGenerator from '../ui/text-generator'
import { MdVpnKey } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { GrGrow } from "react-icons/gr";
import { PiLightbulbFilament } from "react-icons/pi";
import { StickyScroll } from "../ui/sticky-scroll-reveal";
import Sidebar from '../sidebar/Sidebar'
import Img20 from '../../assets/img20.jpg'
import Img21 from '../../assets/img21.jpg'
import Img22 from '../../assets/img22.jpg'
import Img23 from '../../assets/img23.jpg'
import Img24 from "../../assets/img24.jpg"
import Img25 from '../../assets/img25.jpg'
import Img26 from '../../assets/img26.jpg'
import Img27 from '../../assets/img27.jpg'
import Img28 from '../../assets/img28.jpg'
function About() {


    const content = [
        {
            title: "Harsh - Developer",
            description:
                "As a developer and founder of our agency, I lead a team dedicated to crafting innovative digital solutions that transform ideas into impactful experiences",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img20}
                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]  w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
      
        
       
        {
            title: "Sohaib - Developer",
            description:
                "As a developer, I drive innovation and lead our team to deliver cutting-edge digital solutions, blending technical expertise with strategic vision.",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img23}
                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]  w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
        {
            title: "Amisha - Content Manager",
            description:
                "As a content manager and agency founder, I lead our team in crafting innovative digital solutions that transform ideas into impactful experiences.",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img21}
                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]   w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
        {
            title: "Abida -UI/UX Designer",
            description:
                "As a UI/UX designer at our agency, I work with a talented team to create intuitive and engaging digital experiences that bring ideas to life",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img27}

                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]   w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
        {
            title: "Nishar -Developer",
            description:
                "As a developer, I focus on creating innovative digital solutions, turning complex challenges into seamless user experiences with cutting-edge technology.",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img24}
                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]  w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
        
        {
            title: "Ishank - Developer",
            description:
                "As a developer at our agency, I collaborate with a skilled team to deliver cutting-edge digital solutions, turning creative ideas into transformative experiences.",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img22}
                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]   w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
        {
            title: "Vedang - Marketing",
            description:
                "I drive strategic growth with innovative campaigns and data-driven insights, enhancing our agency's brand visibility and positioning us as industry leaders..",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img25}

                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]   w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
        {
            title: "Shivanshu - Marketing",
            description:
                "I lead the creation of impactful marketing campaigns that showcase our agency’s expertise and engage target audiences, delivering measurable results and amplifying our brand.",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img26}

                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]   w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },

        {
            title: "Kshitij - Marketing",
            description:
                "I shape our agency’s brand identity through strategic marketing, crafting compelling narratives that highlight our strengths and attract new opportunities.",
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img28}

                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]   w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
        
        {
            title: "",
            description:''
    ,
            content: (
                <div className="h-full w-full  flex items-center justify-center text-white">
                    <img
                        src={Img28}

                        width={300}
                        height={300}
                        className="h-full translate-x-[-4%] rounded-lg translate-y-[5%]   w-full object-cover"
                        alt="linear board demo"
                    />
                </div>
            ),
        },
    ];


    const people = [
        {
            id: 1,
            name: "Harsh Kandera",
            designation: "Developer",
            image:Img20
                ,
        },
        {
            id: 2,
            name: "Amisha Singh",
            designation: "Content Manager",
            image:Img21,
        },
        {
            id: 3,
            name: "Abida Khatun",
            designation: "UI/UX Designer",
            image:Img27
                ,
        },
        {
            id: 4,
            name: "Sohaib Aftab",
            designation: "Developer",
            image:Img23
               
        },
        {
            id: 5,
            name: "Ishank Kumar",
            designation: "Developer",
            image:Img22
               ,
        },
        {
            id: 6,
            name: "Nishar Ahmad",
            designation: "Developer",
            image:Img24
                ,
        },
        {
            id: 7,
            name: "Vedang Mathur",
            designation: "Marketing",
            image:Img25
               
        },
        {
            id: 8,
            name: "Shivanshu Tirpathi",
            designation: "Marketing",
            image:Img26
               ,
        },
        {
            id: 9,
            name: "Kshitij Sharma",
            designation: "Marketing",
            image:Img28
                ,
        },
    ];

    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (
        <div className='max-w-screen mx-auto overflow-hidden '>
            <div className="cursor-circle" style={{ left: position.x, top: position.y }}></div>
            <div className='absolute min-w-full left-[50%] translate-x-[-50%] z-50 '>

<div className='flex pt-6  gap-2 justify-start ml-6 md:ml-0 md:justify-evenly items-center'>

  
  <div>
    <Icons.logo className='h-14 w-14' />

  </div>
  <div className='hidden md:flex '>
    <Navbar></Navbar>
  </div>


  <div className='md:flex hidden'>
    <Btn name='Get Started' />
  </div>



</div>
</div>

            <div className=' md:hidden'>
                <Sidebar />
            </div>



            <div className='flex flex-col md:flex-row text-4xl font-semibold  gap-24 items-center  text-richyellow-10 tracking-wider mt-32 max-w-[90%] md:max-w-[80%] mx-auto'>

                <div>
                    ABOUT <span className='text-richyellow-100 '>OUR</span> <br /> DESIGNERS
                </div>

                <div className="flex flex-row items-center  mb-10 w-full">
                    <AnimatedTooltip items={people} />
                </div>

            </div>

            <div className='max-w-[80%] mx-auto mt-28'>


                <div className='relative'>
                    <div className='absolute top-[-28px] left-[-80px]'>
                        <svg width="100" height="100" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_146_134" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="114" height="114">
                                <rect x="55.2617" y="113.984" width="80" height="80" transform="rotate(-132.774 55.2617 113.984)" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_146_134)">
                                <path d="M74.5864 41.6123L73.488 69.8752L66.8933 69.6189L67.6742 48.0073L46.0671 47.1086L46.3234 40.5139L74.5864 41.6123ZM59.9057 55.1946L58.8073 83.4575L52.2126 83.2012L52.9936 61.5896L31.3865 60.6909L31.6428 54.0962L59.9057 55.1946Z" fill="#C5FB67" />
                            </g>
                        </svg>


                    </div>
                    <div className='text-richyellow-10 text-4xl pl-4'>
                        DISCOVER <span className='text-richyellow-100'>OUR</span> STORY
                    </div>




                </div>



                <div className='flex flex-col  z-10 px-2 gap-16 justify-center items-center mt-10 relative'>

                    <div className=' gradient rounded-[45px] z-10'>

                        <img src={Img17} className=' w-full h-full md:h-[400px] z-10 translate-x-[-8%] translate-y-[10%] object-cover rounded-[45px] object-bottom' alt="" />

                    </div>


                    <div className=' flex justify-center items-center p-2 md:max-w-[80%] w-full px-2'>

                        <TextGenerator words='At WebSphere, we transform visions into digital realities, growing into a top development agency by delivering innovative, impactful solutions that drive client success.' />;


                    </div>

                    {/* <div className='w-4/5  h-[400px] bg-no-repeat z-10 bg-cover bg-center rounded-[45px] ' style={{ backgroundImage: `url(${Img17}) ` }}>
                    </div> */}

                    {/* 
                    <div className='z-50'>
                        <svg width="350" height="350" viewBox="0 0 651 651" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="650" height="650" rx="44.5" fill="#D9D9D9" fill-opacity="0.1" stroke="url(#paint0_linear_145_31)" />
                            <defs>
                                <linearGradient id="paint0_linear_145_31" x1="527" y1="-4.35965e-05" x2="40" y2="612" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#F2751A" />
                                    <stop offset="1" stop-color="#F2751A" stop-opacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <div>

                        </div>

                    </div> */}

                    <div className='about-glass absolute top-[-40px] right-0 z-0'>

                    </div>
                    <div className='about-glass2 absolute bottom-[-90px] left-[-300px] z-0 '>

                    </div>

                </div>
            </div>
            <div>
                <div className=' font-light  mt-28 '>
                    <div className='flex flex-col justify-center gap-2 font-light items-center'>
                        <div className='text-richyellow-100 text-3xl'>
                            GET TO KNOW
                        </div>
                        <div className='text-richyellow-10 text-5xl'>
                            THE TEAM
                        </div>
                    </div>
                </div>
                {/* 
                <div className='mt-20 overflow-x-scroll invisible-scrollbar'>
                    <Teams />
                </div> */}

                <div className="max-w-screen mx-auto pt-20">
                    <StickyScroll content={content} />
                </div>
            </div>


            <div className='max-w-screen mx-auto px-2'>
                <div className=' font-light  mt-28 '>
                    <div className='flex flex-col justify-center gap-2 font-light items-center'>
                        <div className='text-richyellow-100 text-3xl'>
                            OUR
                        </div>
                        <div className='text-richyellow-10 text-5xl'>
                            CORE VALUES
                        </div>
                    </div>
                </div>


                <div className='text-sm md:marker:text-lg lg:text-xl mt-6 text-center text-neutral-200 '>
                    Core Values are critical behavioral values that we hold everyone accountable for at Enterpret.


                </div>

                <div className='grid lg:grid-cols-3 max-w-[80%] mx-auto gap-4  mt-20 md:grid-cols-2 grid-cols-1'>

                    <div className='flex flex-col gap-2 text-white  bg-[#D9D9D9]  bg-opacity-10 backdrop-blur-sm rounded-lg p-6'>

                        <div >
                            <MdVpnKey className='text-5xl text-richyellow-100 font-extrabold' />
                        </div>

                        <div className='text-3xl font-bold'>
                            Be an owner, Not a renter
                        </div>

                        <div>
                            We all win when WebSphere wins. We play for the team and do what is needed to make WebSphere win.


                        </div>
                    </div>

                    <div className='flex flex-col gap-2 text-white  bg-[#D9D9D9]  bg-opacity-10 backdrop-blur-sm rounded-lg p-6'>

                        <div >
                            <FaHeart className='text-5xl text-richyellow-100 font-extrabold' />
                        </div>

                        <div className='text-3xl font-bold'>
                            Care personally, Challenge directly

                        </div>

                        <div>
                            We care personally for our teammates. We give and ask for direct and actionable feedback.



                        </div>
                    </div>
                    <div className='flex flex-col gap-2 text-white  bg-[#D9D9D9]  bg-opacity-10 backdrop-blur-sm rounded-lg p-6'>

                        <div >
                            < GrGrow className='text-5xl text-richyellow-100 font-extrabold' />
                        </div>

                        <div className='text-3xl font-bold'>
                            Humble growth mindset

                        </div>

                        <div>
                            We carry a mindset of humility, coupled with a strong desire to learn and get better.







                        </div>
                    </div>
                </div>


            </div>


            <div className='mt-20 max-w-[90%] mx-auto'>

                <div className='h-[1px] w-[80%]  mx-auto bg-gray-700'>

                </div>

                <div className='grid lg:grid-cols-6  grid-rows-6 gap-2 mt-20  text-white '>

                    <div className='row-span-2 lg:col-span-2 '>

                        <div className=' m-2'>
                            <PiLightbulbFilament className='text-5xl font-bold text-richyellow-100' />
                        </div>

                        <div className='flex flex-col gap-2' >
                            <div className='text-3xl  font-bold'>
                                Operating Principles
                            </div>
                            <div className='text-base '>
                                Operating Principles guide how we operate at Enterpret. They add clarity when we are evaluating and prioritizing between projects.
                            </div>


                        </div>

                    </div>

                    <div className='row-span-4 lg:col-span-4 flex flex-col gap-4 '>

                        <div className='flex flex-col w-full'>

                            <div className='flex flex-col bg-[#D9D9D9] p-4 border-l-4 border-richyellow-100 rounded-lg  bg-opacity-10 backdrop-blur-sm  gap-2'>
                                <div className='text-3xl font-bold'>
                                    Solve customer pain


                                </div>
                                <div className='text-base text-neutral-200'>
                                    We exist to solve customer pain - continuously learn about our customer's most critical pain points and solve them.
                                </div>
                            </div>

                        </div>

                        <div className='flex flex-col w-full'>

                            <div className='flex flex-col bg-[#D9D9D9] p-4 border-l-4 border-richyellow-100 rounded-lg  bg-opacity-10 backdrop-blur-sm  gap-2'>
                                <div className='text-3xl font-bold'>
                                    Ambition shapes reality



                                </div>
                                <div className='text-base text-neutral-200'>
                                    What we achieve is defined by what we aim to accomplish. We set ambitious goals for ourselves and back ourselves to hit them. Failures we encounter along the way are fuel for us to learn and get better.
                                </div>
                            </div>

                        </div>
                        <div className='flex flex-col w-full'>

                            <div className='flex flex-col bg-[#D9D9D9] p-4 border-l-4 border-richyellow-100 rounded-lg  bg-opacity-10 backdrop-blur-sm  gap-2'>
                                <div className='text-3xl font-bold'>
                                    Fast paced yet detailed oriented



                                </div>
                                <div className='text-base text-neutral-200'>
                                    Build fast. Learn fast. Repeat. But we never sacrifice the details that matter.







                                </div>
                            </div>

                        </div>

                    </div>
                </div>


                <div className='h-[1px] mt-4 w-[80%] mx-auto bg-gray-700'>

                </div>
            </div>

            <div className='mt-20'>
                <Footer></Footer>
            </div>

        </div>
    )
}

export default About