
import {  useRef } from "react";
import {  useScroll, useTransform } from "framer-motion";
import img5 from "../../assets/bluegr.png"

import { cn } from "../../utils/cn";
import img4 from "../../assets/img4.jpg"
import { LuArrowUpRight } from "react-icons/lu";

import { motion, Variants } from "framer-motion";




export const TextReveal= ({
  text,
  className,
}) => {
  const targetRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const words = text.split(" ");

  return (
    <div ref={targetRef} className={cn("relative  z-0 h-[200vh]", className)}>

      <div
        className={
          "sticky top-12 mx-auto flex p-2 md:pl-6 max-w-4xl md:flex-row  flex-col items-center justify-center  bg-[#D9D9D9] rounded-[45px] bg-opacity-10 "
        }
      >
        <div className='w-full   relative top-0 '>

        <img src={img4} alt="" className='rounded-[45px] w-full h-1/2 object-cover' />

        </div>

      
        <div>
        <p
          ref={targetRef}
          className={
            "flex flex-wrap p-5 text-2xl font-bold text-white dark:text-white/30 md:text-3xl  lg:text-4xl xl:text-5xl"
          }
        >
          {words.map((word, i) => {
            const start = i / words.length;
            const end = start + 1 / words.length;
            return (
              <Word key={i} progress={scrollYProgress} range={[start, end]}>
                {word}
              </Word>
            );
          })}
          </p>
        </div>
        <div className='absolute top-[-100px] right-0 w-[300px] z-0 '>
          <img src={img5} alt="" />
        </div>

        <div className='w-28 h-28 hidden md:flex rounded-full animate-pulse absolute right-[-20px] top-[-50px]  bg-richblack-300 flex-1 justify-center items-center cursor-pointer'>

          <div className='text-richyellow-10 md:text-7xl  text-5xl'>
            <LuArrowUpRight />

          </div>
        </div>

      </div>
    </div>
  );
};


const Word= ({ children, progress, range }) => {
  const opacity = useTransform(progress, range, [0, 1]);
  return (
    <span className="xl:lg-3 z-10 relative mx-1 lg:mx-2.5">
      <span className={"absolute opacity-30"}>{children}</span>
      <motion.span
        style={{ opacity: opacity }}
        className={"text-white  dark:text-white"}
      >
        {children}
      </motion.span>
    </span>
  );
};





export function WordFadeIn({
  words,
  delay = 0.15,
  variants = {
    hidden: { opacity: 0 },
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: { delay: i * delay },
    }),
  },
  className,
}) {
  const _words = words.split(" ");

  return (
    <motion.h1
      variants={variants}
      initial="hidden"
      animate="visible"
      className={cn(
        "font-inter text-2xl font-bold tracking-[-0.02em] text-white drop-shadow-sm dark:text-white z-10 md:text-3xl  lg:text-4xl xl:text-5xl md:leading-[5rem]",
        className,
      )}
    >
      {_words.map((word, i) => (
        <motion.span key={word} variants={variants} custom={i}>
          {word}{" "}
        </motion.span>
      ))}
    </motion.h1>
  );
}
