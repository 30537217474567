"use client";

import React, { useContext, forwardRef } from "react";
import { DashIcon } from "@radix-ui/react-icons";
import { OTPInput, OTPInputContext } from "input-otp";
import { cn } from "../../utils/cn";

const InputOTP = forwardRef(function InputOTP(
  { className, containerClassName, ...props },
  ref
) {
  return (
    <OTPInput
      ref={ref}
      containerClassName={cn(
        "flex items-center gap-2 has-[:disabled]:opacity-50",
        containerClassName
      )}
      className={cn("disabled:cursor-not-allowed", className)}
      {...props}
    />
  );
});

InputOTP.displayName = "InputOTP";

const InputOTPGroup = forwardRef(function InputOTPGroup(
  { className, ...props },
  ref
) {
  return (
    <div ref={ref} className={cn("flex items-center", className)} {...props} />
  );
});

InputOTPGroup.displayName = "InputOTPGroup";

const InputOTPSlot = forwardRef(function InputOTPSlot(
  { index, className, ...props },
  ref
) {
  const inputOTPContext = useContext(OTPInputContext);
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

  return (
    <div
      ref={ref}
      className={cn(
        "relative flex h-9 w-9 items-center justify-center border-y border-r border-input text-sm shadow-sm transition-all first:rounded-l-md first:border-l last:rounded-r-md",
        isActive && "z-10 ring-1 ring-ring",
        className
      )}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div className="h-4 w-px animate-caret-blink bg-foreground duration-1000" />
        </div>
      )}
    </div>
  );
});

InputOTPSlot.displayName = "InputOTPSlot";

const InputOTPSeparator = forwardRef(function InputOTPSeparator(props, ref) {
  return (
    <div ref={ref} role="separator" {...props}>
      <DashIcon />
    </div>
  );
});

InputOTPSeparator.displayName = "InputOTPSeparator";

export  { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };
