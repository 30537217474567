import React from 'react'
import { useState } from 'react'
import { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { apiConnector } from '../../service/apiconnector';
import { Button } from '../ui/Button'

import { Link } from 'react-router-dom'
function BlogWriting({ placeholder }) {


  const [formData, setFormData] = useState({ title: "", subheading: "" });
  const [published, setPublished] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false)
  const inputRef = useRef()
  const [content, setContent] = useState('');

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setImage(file)

  };
  const submithandler = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();

    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }


    formDataToSend.append(`image`, image);
    formDataToSend.append('published', published);
    formDataToSend.append('content', content);

    for (const [key, value] of formDataToSend.entries()) {
      console.log(`${key}: ${value}`);
    }


    try {
      setLoading(true)

      const result = await apiConnector('POST',  '' + '/api/blog_post', formDataToSend);




      setLoading(false)
    } catch (error) {
      setLoading(false)
      // toast.error(error.response?.data?.message || "Failed To Submit");

      // console.error('An error occurred:', error);
    }

  }
  const { title, subheading } = formData;
  function changehandler(event) {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,


      }
    })
  }
  const editor = useRef(null);


  const config = useMemo(() => ({
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: placeholder || 'Start typing...' // Corrected typo in the placeholder text
    ,
    style: {
      height: '70vh',
      width: '70vw'
    },
  }), [placeholder]);

  return (

    <div className=' flex flex-col items-center  max-w-screen  min-h-screen bg-white pb-28 mx-auto pt-20'>

      


      <form >
        <div className="space-y-12  ">
          <div className=''>

            <div className='  flex justify-start items-start'>

              <div className='flex gap-10 '>
                <Link to='/admin/dashboard'>
                  <Button variant="secondary" className='rounded-full '>
                    Blogs
                  </Button>
                </Link>


                <Link to='/admin/write-blog'>
                  <Button variant="secondary">
                    New Blogs
                  </Button>
                </Link>

                <Link>
                  <Button variant="secondary">
                    Contact
                  </Button>
                </Link>

              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Create a new blog</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Blog Title
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300  sm:max-w-md">
                    <input
                      name="title"
                      value={title}
                      type="text"
                      placeholder="introduction to chatGpt"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      onChange={changehandler}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Blog Description
                </label>
                <div className="mt-2">
                  <textarea
                    name="subheading"
                    value={subheading}
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    defaultValue={''}
                    onChange={changehandler}
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about your new blog.</p>
              </div>



              <div className="col-span-full">
                <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                  Cover Photo
                </label>
                <div className="mt-2 flex items-center gap-x-3" onClick={() => inputRef.current.click()}>
                  <input id="file-upload" name="file" type="file" className="sr-only" ref={inputRef} multiple hidden onChange={handleImageChange} />
                  {
                    image ? <img src={URL.createObjectURL(image)} className="h-16 w-16 rounded-full object-cover " /> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-16 w-16 rounded-full text-gray-300" aria-hidden="true">
                      <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z" clipRule="evenodd" />
                    </svg>

                  }
                  <button
                    type="button"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Change
                  </button>
                </div>


              </div>



            </div>
          </div>

          <div className=" pb-12">


            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">



              <div className="sm:col-span-3">
                <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                  Status
                </label>
                <div className="mt-2">
                  <select
                    id="status"
                    name="status"
                    autoComplete="off"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    onChange={(e) => setPublished(e.target.value === 'true')}
                  >
                    <option value="true">Published</option>
                    <option value="false">Draft</option>
                  </select>
                </div>
              </div>

            </div>

            <div className='flex justify-center mt-16 items-center '>

              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { }}

              />
            </div>
          </div>

        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </button>
          <button
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          
          onClick={submithandler}
          >
            Save
          </button>
        </div>
      </form>


    </div>
  )
}

export default BlogWriting