import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../sidebar/Sidebar'

import Icons from "../logo"
import Btn from "../ui/btn"

import Footer from "../Footer"

import { HoverEffect } from '../ui/card-hover-effect';
function Services() {
  const [projects, setShowAllPlans] = useState([

    {
      heading: 'Standard',
      originalPrices: '10,000',
      readmore: false,
      offerPrice: '7,999',
      plans: [
        '5 pages website',
        '1 year free domain name (.com.in.org)',
        '1 year free hosting (unlimited space)',
        'Dynamic website (premium design)',
        'Admin access',
        'Lifetime 24/7 free hosting support',
        'Unlimited images & videos uploaded',
        'Free SSL certificates',
        '5 free email id',
        'SEO friendly website',
        '100% responsive website',
        'Live chat integration',
        'Payment gateway integration',
        'Social Media Integration',
        'Call Button Integration',
        '1 Year Free Technical Support For Website',
        'Annual Renewal For Hosting Rs.3000'
      ]
    }
    ,
    {
      heading: 'Premium',
      originalPrices: '19,000',
      offerPrice: '15,999',
      readmore: false,
      plans: [
        '5 pages website',
        '1 year free domain name (.com.in.org)',
        '1 year free hosting (unlimited space)',
        'Dynamic website (premium design)',
        'Admin access',
        'Lifetime 24/7 free hosting support',
        'Unlimited images & videos uploaded',
        'Free SSL certificates',
        '5 free email id',
        'SEO friendly website',
        '100% responsive website',
        'Live chat integration',
        'Payment gateway integration',
        'Social Media Integration',
        'Call Button Integration',
        '1 Year Free Technical Support For Website',
        'Annual Renewal For Hosting Rs.3000'
      ]
    },
    {
      heading: 'Custom',
      originalPrices: '????',
      offerPrice: '????',
      readmore: false,
      plans: [
        '12 pages Website',
        '1 Year Free Domain Name ( .com .in .org )',
        '1 Year Free Hosting ( Unlimited Space )',
        'Dynamic Website ( Premium Design )',
        'Admin Access',
        'Google Search Console Setup',
        'Lifetime 24/7 Free Hosting Support',
        'Unlimited Images & Videos Upload',
        'Free SSL Certificates',
        '10 Free Email Id',
        'SEO Friendly Website',
        '100% Responsive Website',
        'Live Chat Integration',
        'Payment Gateway Integration',
        'Social Media Integration',
        'Call Button Integration',
        'WhatsApp Button Integration',
        'Inquiry Form',
        'Woocommece Features',
        '1 Year Free Technical Support For Website',
        'Annual Renewal For Hosting Rs.3000'
      ]
    },

    {
      heading: 'Premium Design E-commerce Plan',
      originalPrices: '25,000',
      offerPrice: '19,999',
      readmore: false,
      plans: [
        'Pages: According to Requirement',
        '1 Year Free Domain Name ( .com .in .org )',
        '1 Year Free Hosting ( Unlimited Space )',
        'Dynamic Website',
        'Admin Access',
        'Google Search Console Setup',
        'Lifetime 24/7 Free Hosting Support',
        'Unlimited Images & Videos Upload',
        'Free SSL Certificates',
        '10 Free Email Id',
        'SEO Friendly Website',
        '100% Responsive Website',
        'Live Chat Integration',
        'Payment Gateway Integration',
        'Social Media Integration',
        'Call Button Integration',
        'WhatsApp Button Integration',
        'Inquiry Form',
        'Woocommerce Features',
        '1 Year 24/7 Free Support For Website',
        'Annual Renewal Rs.4000'
      ]
    }
    ,
    {
      heading: 'Multi-vendor Business & E-commerce Plan',
      originalPrices: '40,000',
      offerPrice: '35,999',
      readmore: false,
      plans: [
        '30 pages Website',
        '1 Year Free Domain Name ( .com .in .org )',
        '1 Year Free Hosting ( Unlimited Space )',
        '20 Product Categories',
        '30 Product Listing From Our Side',
        'Premium Design',
        'Dynamic Website',
        'Admin Access',
        'Google Search Console Setup',
        'Lifetime 24/7 Free Hosting Support',
        'Unlimited Images & Videos Upload',
        'Free SSL Certificates',
        '10 Free Email Id',
        'Premium Theme',
        'SEO Friendly Website',
        '100% Responsive Website',
        'Live Chat Integration',
        'Payment Gateway Integration',
        'Social Media Integration',
        'Call Button Integration',
        'WhatsApp Button Integration',
        'Inquiry Form',
        'Ecommerce Features',
        'Product Variation Features',
        'Auto Invoice Bill Generator Features',
        'Wallet System Features',
        'Order Notification Features',
        'OTP Verification Features',
        '1 Year Free Technical Support For Website',
        'Annual Renewal For Hosting Rs.4000'
      ]
    },
    {
      heading: 'Custom Multi-vendor Business & E-commerce Plan',
      originalPrices: '????',
      offerPrice: '????',
      readmore: false,
      plans: [
        '40 pages Website',
        '1 Year Free Domain Name ( .com .in .org )',
        '1 Year Free Hosting ( Unlimited Space )',
        '50 Products Categories',
        '30 Products Listing from our side',
        'Premium Design',
        'Dynamic Website',
        'Admin Access',
        'Google Search Console Setup',
        'Dynamic Multi-Vendor Website',
        'Lifetime 24/7 Free Hosting Support',
        'Unlimited Images & Videos Upload',
        'Free SSL Certificates',
        '20 Free Email Id',
        'SEO Friendly Website',
        '100% Responsive Website',
        'Live Chat Integration',
        'Payment Gateway Integration',
        'Social Media Integration',
        'Call Button Integration',
        'WhatsApp Button Integration',
        'Inquiry Form',
        'Multi-Level Ecommerce Features',
        'Product Variation Features',
        'Auto Invoice Bill Generator Features',
        'Wallet System Features',
        'Order Notification Features',
        'OTP Verification Features',
        '1 Year Free Technical Support For Website',
        'Annual Renewal For Hosting Rs.4000'
      ]
    }
  ]
  );






  const changehandle = (index) => {
    setShowAllPlans((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        readmore: !updatedData[index].readmore,
      };
      return updatedData;
    });
  };





  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  return (
    <div className='max-w-screen mx-auto overflow-hidden px-2' >
      <div className="cursor-circle" style={{ left: position.x, top: position.y }}></div>
      <div className='absolute min-w-full left-[50%] translate-x-[-50%] z-50 '>

<div className='flex pt-6  gap-2 justify-start ml-6 md:ml-0 md:justify-evenly items-center'>

  
  <div>
    <Icons.logo className='h-14 w-14' />

  </div>
  <div className='hidden md:flex '>
    <Navbar></Navbar>
  </div>


  <div className='md:flex hidden'>
    <Btn name='Get Started' />
  </div>



</div>
</div>

      <div className=' md:hidden'>
        <Sidebar />
      </div>

      <div className=' max-w-full  md:max-w-[90%] mx-auto'>


        <div className=' font-light  mt-32 '>
          <div className='flex flex-col justify-center gap-[4px] font-light items-center'>
            <div className='text-richyellow-100 text-xl'>
              OUR
            </div>
            <div className='text-richyellow-10 text-3xl'>
              SERVICES
            </div>

          </div>
        </div>


        {/* <div className='flex flex-col gap-12 mt-8 justify-center '>
                    <div className='w-4/5 h-96 bg-cover bg-no-repeat' style={{ backgroundImage: `url(${img14}) ` }}>

                    </div>

                    <div className='w-4/5 h-96 bg-cover bg-no-repeat' style={{ backgroundImage: `url(${img15}) ` }}>

                    </div>
                    <div className='w-4/5 h-96 bg-cover bg-no-repeat' style={{ backgroundImage: `url(${img16}) ` }}>

                    </div>

                </div> */}

        {/* <div className='flex gap-6 mt-10 text-richyellow-10 flex-wrap'>
             
 {showAllPlans.map((detail, index) => {
  
   return (
    <div key={index} className='glassmorphism w-96 h-full flex flex-col gap-4 p-4 items-center'>
      <div className='text-3xl text-center'>{detail.heading}</div>
      <div className='flex flex-col items-center gap-2'>
        <div className='line-through text-3xl'>₹ {detail.originalPrices}</div>
        <div className='text-2xl text-richyellow-100'>₹ {detail.offerPrice}</div>
      </div>
      <div>Features Include</div>
      <div>
      {detail.readmore
  ? detail.plans.map((plan, index) => (
      <div
        key={index}
        className={`flex ${
          detail.readmore ?  'transition-all duration-700 ease-in-out max-h-[200vh]' : 'max-h-0 overflow-hidden transition-all duration-500 ease-in-out'
        } items-center gap-2`}
      >
        <GiCheckMark />
        <p className='m-2'>{plan}</p>
      </div>
    ))
  : detail.plans.slice(0, 8).map((plan, index) => (
      <div key={index} className='flex items-center gap-2'>
        <GiCheckMark />
        <p className='m-2'>{plan}</p>
      </div>
    ))}

          

      </div>
      {detail.plans.length > 8 && (
        <button
          className='text-richyellow-100 underline cursor-pointer'
          onClick={() => changehandle(index)}
        >
          {detail.readmore ? 'Read Less' : 'Read More'}
        </button>
      )}
      <div className='flex justify-center w-full items-center'>
        <div className='w-32 h-10 bg-richyellow-100 rounded-full flex justify-center items-center cursor-pointer'>
          <div className='text-richblack-200 flex gap-2 justify-center items-center'>
            <div>Call now</div>
            <div className='text-3xl'>
              <IoIosArrowRoundForward />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})}
  

                    </div> */}


        <div className=" mx-auto px-8">
          <HoverEffect items={projects} />
        </div>

      </div>
      <Footer></Footer>

    </div>
  )
}

export default Services

// export const projects = [
//   {
//     title: "Stripe",
//     description:
//       "A technology company that builds economic infrastructure for the internet.",
//     link: "https://stripe.com",
//   },
//   {
//     title: "Netflix",
//     description:
//       "A streaming service that offers a wide variety of award-winning TV shows, movies, anime, documentaries, and more on thousands of internet-connected devices.",
//     link: "https://netflix.com",
//   },
//   {
//     title: "Google",
//     description:
//       "A multinational technology company that specializes in Internet-related services and products.",
//     link: "https://google.com",
//   },
//   {
//     title: "Meta",
//     description:
//       "A technology company that focuses on building products that advance Facebook's mission of bringing the world closer together.",
//     link: "https://meta.com",
//   },
//   {
//     title: "Amazon",
//     description:
//       "A multinational technology company focusing on e-commerce, cloud computing, digital streaming, and artificial intelligence.",
//     link: "https://amazon.com",
//   },
//   {
//     title: "Microsoft",
//     description:
//       "A multinational technology company that develops, manufactures, licenses, supports, and sells computer software, consumer electronics, personal computers, and related services.",
//     link: "https://microsoft.com",
//   },
// ];