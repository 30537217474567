import { Button } from "../ui/Button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card"
import { Input } from "../ui/input"
import { useToast } from "../ui/use-toast"
import { ToastAction } from "../ui/toast"
import { useAuth } from "./context"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../ui/tabs"
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../ui/otp-input"
import { apiConnector } from '../../service/apiconnector';
import { useState, react } from "react"
import { jwtDecode } from 'jwt-decode';
import { useRef, useMemo } from 'react';

import { UserCircleIcon } from '@heroicons/react/24/solid'

import Img18 from '../../assets/img18.jpg'
function SignIn() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [value, setValue] = useState("")
  const [image, setImage] = useState(null);
  const inputRef = useRef()
  const [name, setName] = useState('')
  const { login } = useAuth()

  const { toast } = useToast()
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setImage(file)

  };

  const signIn = async () => {
    try {
      if (!email || !password || !name || !image) {
        return toast({
          variant: "destructive",
          title: "Uh oh! Please Enter All Fields",
          description: "There was a problem with your request.",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        })
      }

      const result = await apiConnector("POST", '' + '/api/sendotp', {
        email
      });

      console.log(result)



      if (result.data.success) {
        setEmailSent(true);
      }


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };





  const loginSubmit = async () => {
    try {
      if (!email || !password) {
        return toast({
          variant: "destructive",
          title: "Uh oh! Please Enter All Fields",
          description: "There was a problem with your request.",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        })
      }
      const result = await apiConnector("POST", '' + '/api/login', {
        email: email,
        password: password
      });

      console.log(result)

      if (result.data.success) {
        localStorage.setItem("token", result.data.token);
        const decodedToken = jwtDecode(result.data.token);
        const userData = {

          isAuthenticated: true,
          user: decodedToken,

        }

        login(userData)

        toast({
          title: "Login Successful",
        })
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const verifyOtp = async () => {
    try {
      if (!email || !password || !name || !image) {
        return toast({
          variant: "destructive",
          title: "Uh oh! Please Enter All Fields",
          description: "There was a problem with your request.",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        })
      }

      const formDataToSend = new FormData();


      formDataToSend.append(`image`, image);
      formDataToSend.append('name', name);
      formDataToSend.append('email', email);
      formDataToSend.append('password', password);

      const result = await apiConnector("POST", '' + '/api/Signup', formDataToSend);

      console.log(result)
      if (result.data.success) {
        return toast({
          title: "Welcome to Websphere !",
        })
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (

    <div className="flex h-screen w-screen justify-center bg-white items-center"   >
      <Tabs defaultValue="account" className="w-[400px]">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="account">Login</TabsTrigger>
          <TabsTrigger value="NewAccount">New Account</TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <Card>
            <CardHeader>
              <CardTitle>Account</CardTitle>
              <CardDescription>
                Login into your account and start blogging .
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="space-y-1">
                <label htmlFor="name">Email</label>
                <Input id="name" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
              </div>
              <div className="space-y-1">
                <label htmlFor="username">Password</label>
                <Input id="username" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="********" />
              </div>
            </CardContent>
            <CardFooter>
              <Button onClick={loginSubmit}>Login</Button>
            </CardFooter>
          </Card>
        </TabsContent>
        <TabsContent value="NewAccount">
          <Card>
            {
              emailSent ?
                <>
                  <CardHeader>
                    <CardTitle>New Account</CardTitle>
                    <CardDescription>
                      Email Sent to Admin Verify your account with admin
                    </CardDescription>
                  </CardHeader>

                  <CardContent className="space-y-2 ">
                    <div className="space-y-2 flex justify-center flex-col items-center">
                      <InputOTP
                        maxLength={6}
                        value={value}
                        onChange={(value) => setValue(value)}
                      >
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                      <div className="text-center text-sm">
                        {value === "" ? (
                          <>Enter your one-time password.</>
                        ) : (
                          <>You entered: {value}</>
                        )}
                      </div>
                    </div>
                  </CardContent>
                  <CardFooter>
                    <Button onClick={verifyOtp}>Next</Button>
                  </CardFooter>
                </> : <>
                  <CardHeader>
                    <CardTitle>New Account</CardTitle>
                    <CardDescription>
                      Verify your account and start bloging
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-2">
                    <div className="space-y-1">
                      <label htmlFor="current">Email</label>
                      <Input id="current" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                    </div>

                    <div className="sm:col-span-4">
                      <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                        Username
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300  sm:max-w-md">

                          <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="janesmith"
                            autoComplete="username"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                        Photo
                      </label>
                      <div className="mt-2 flex items-center gap-x-3" onClick={() => inputRef.current.click()}>
                        <input id="file-upload" name="file" type="file" className="sr-only" ref={inputRef} multiple hidden onChange={handleImageChange} />
                        {
                          image ? <img src={URL.createObjectURL(image)} className="h-16 w-16 rounded-full object-cover " /> : <UserCircleIcon aria-hidden="true" className="h-12 w-12 text-gray-300" />


                        }
                        <button
                          type="button"
                          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Change
                        </button>
                      </div>

                    </div>
                    <div className="space-y-1">
                      <label htmlFor="new">Password</label>
                      <Input id="new" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="********" />
                    </div>
                  </CardContent>
                  <CardFooter>
                    <Button onClick={signIn}>Next</Button>
                  </CardFooter>
                </>
            }
          </Card>
        </TabsContent>
      </Tabs>


    </div>

  )
}


export default SignIn;

