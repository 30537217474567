import React, { useRef } from 'react'
import LoadingBar from 'react-top-loading-bar'
import { Link, useLocation } from "react-router-dom"

function Navbar() {
  const ref = useRef(null)
  const location = useLocation()
  return (

<div>
<LoadingBar color='#C5FB67' ref={ref} />
<div className='text-richyellow-10 flex items-center h-12 glassmorphism1 border-none rounded-full px-2  font-inter'>




<div>
  <ul className='flex  text-base font-light cursor-pointer'>

    <Link to="/">
      <li className={`navbar `} onClick={() => ref.current.continuousStart()}>
        <button className="btn hover:text-richyellow-100">
          <span className={`btn-text-one ${location.pathname === '/' ? 'text-richyellow-100' : (false)}`}>HOME</span>
          <span className={`btn-text-two ${location.pathname === '/' ? 'text-richyellow-100' : (false)}`}>HOME</span>
        </button>
      </li>
    </Link>


    <Link to="/about">
      <li className='navbar' onClick={() => ref.current.continuousStart()} >
        <button className="btn hover:text-richyellow-100">
          <span className={`btn-text-one ${location.pathname === '/about' ? 'text-richyellow-100' : (false)}`}>ABOUT</span>
          <span className={`btn-text-two ${location.pathname === '/about' ? 'text-richyellow-100' : (false)}`}>ABOUT</span>
        </button>
      </li>

    </Link>

    <Link to="/services">
      <li className='navbar' onClick={() => ref.current.continuousStart()}>
        <button className="btn hover:text-richyellow-100">
          <span className={`btn-text-one ${location.pathname === '/services' ? 'text-richyellow-100' : (false)}`}>SERVICES</span>
          <span className={`btn-text-two ${location.pathname === '/services' ? 'text-richyellow-100' : (false)}`}>SERVICES</span>
        </button>
      </li>
    </Link>
<Link to='/projects'>

<li className='navbar' onClick={() => ref.current.continuousStart()}>
      <button className="btn hover:text-richyellow-100">
        <span className={`btn-text-one ${location.pathname === '/projects' ? 'text-richyellow-100' : (false)}`}>PROJECTS</span>
        <span className={`btn-text-two ${location.pathname === '/projects' ? 'text-richyellow-100' : (false)}`}>PROJECTS</span>
      </button>
    </li>
</Link>
   

    <Link to='/blogs'>

      <li className='navbar' onClick={() => ref.current.continuousStart()}>
        <button className="btn hover:text-richyellow-100">
          <span className={`btn-text-one ${location.pathname === '/blogs' ? 'text-richyellow-100' : (false)}`}>BLOG</span>
          <span className={`btn-text-two ${location.pathname === '/blogs' ? 'text-richyellow-100' : (false)}`}>BLOG</span>
        </button>
      </li>
    </Link>
    <Link to='/contact'>

      <li className='navbar' onClick={() => ref.current.continuousStart()}>
        <button className=" btn hover:text-richyellow-100">
          <span className={`btn-text-one ${location.pathname === '/contact' ? 'text-richyellow-100' : (false)}`}>CONTACT</span>
          <span className={`btn-text-two ${location.pathname === '/contact' ? 'text-richyellow-100' : (false)}`}>CONTACT</span>
        </button>
      </li>
    </Link>
  </ul>

</div>

</div>
</div>
   
  )
}

export default Navbar
