import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from './context';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, login, logout } = useAuth();
  let location = useLocation();
  const navigate = useNavigate();


      const token = localStorage.getItem('token');

      const decodedToken = token && jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if ( !token || decodedToken.exp < currentTime) {
       return <Navigate to="/admin/sign-in" state={{ from: location}} replace />  
      }




  return <Outlet />

};

export default ProtectedRoute;
