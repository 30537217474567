import { cn } from "../../utils/cn";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useState } from "react";
import { GiCheckMark } from "react-icons/gi";

export const HoverEffect = ({
    items,
    className,
}) => {
    let [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <div
            className={cn(
                "grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3  py-10",
                className
            )}
        >
            {items.map((item, idx) => (
                <Link
                    href={item?.link}
                    key={item?.link}
                    className="relative group  block p-2 h-full w-full"
                    onMouseEnter={() => setHoveredIndex(idx)}
                    onMouseLeave={() => setHoveredIndex(null)}
                >
                    <AnimatePresence>
                        {hoveredIndex === idx && (
                            <motion.span
                                className="absolute inset-0 h-full w-full bg-richyellow-100 dark:bg-slate-800/[0.8] block  rounded-3xl"
                                layoutId="hoverBackground"
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    transition: { duration: 0.15 },
                                }}
                                exit={{
                                    opacity: 0,
                                    transition: { duration: 0.15, delay: 0.2 },
                                }}
                            />
                        )}
                    </AnimatePresence>
                    <Card>


                        <CardTitle className='text-3xl' >{item.heading}</CardTitle>
                        <CardTitle className='line-through text-xl'>₹ {item.originalPrices}</CardTitle>
                        <CardTitle className='text-2xl text-richyellow-100 mb-4'>₹ {item.offerPrice}</CardTitle>

                        {
                            item.plans.slice(0, 8).map((plan, index) => (
                                <div
                                    key={index}
                                    className={`flex  items-center gap-2`}
                                >

                                    <div className="w-4 h-4 bg-richyellow-100 mt-2 flex justify-center items-center rounded-full text-black">

                                        <GiCheckMark className="text-xs" />
                                    </div>

                                    <CardDescription>{plan}</CardDescription>
                                </div>


                            ))
                        }



                    </Card>
                </Link>
            ))}
        </div>
    );
};

export const Card = ({
    className,
    children,
}) => {
    return (
        <div
            className={cn(
                "rounded-2xl h-full w-full p-4 overflow-hidden bg-black border border-transparent dark:border-white/[0.2] group-hover:border-slate-700 relative z-20",
                className
            )}
        >
            <div className="relative z-50">
                <div className="p-4">{children}</div>
            </div>
        </div>
    );
};
export const CardTitle = ({
    className,
    children,
}) => {
    return (
        <h4 className={cn("text-zinc-100 font-bold tracking-wide mt-4", className)}>
            {children}
        </h4>
    );
};
export const CardDescription = ({
    className,
    children,
}) => {
    return (
        <p
            className={cn(
                "mt-2 text-zinc-400 tracking-wide leading-relaxed text-sm",
                className
            )}
        >
            {children}
        </p>
    );
};


// {
//     heading: 'Standard',
//         originalPrices: '10,000',
//             readmore: false,
//                 offerPrice: '7,999',
//                     plans: [
//                         '5 pages website',
//                         '1 year free domain name (.com.in.org)',
//                         '1 year free hosting (unlimited space)',
//                         'Dynamic website (premium design)',
//                         'Admin access',
//                         'Lifetime 24/7 free hosting support',
//                         'Unlimited images & videos uploaded',
//                         'Free SSL certificates',
//                         '5 free email id',
//                         'SEO friendly website',
//                         '100% responsive website',
//                         'Live chat integration',
//                         'Payment gateway integration',
//                         'Social Media Integration',
//                         'Call Button Integration',
//                         '1 Year Free Technical Support For Website',
//                         'Annual Renewal For Hosting Rs.3000'
//                     ]
// }