import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './components/admin/context';
import { Toaster } from "./components/ui/toaster"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <AuthProvider>
      <BrowserRouter>
        <App />
        <Toaster />
      </BrowserRouter>
    </AuthProvider>

);


reportWebVitals();
