import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import { useToast } from "../ui/use-toast"
import { IoIosArrowRoundForward } from "react-icons/io";
import Icons from "../logo"
import Btn from '../ui/btn'
import Footer from "../Footer"
import img13 from "../../assets/img13.jpg"
import { LuPlus, LuMinus } from "react-icons/lu";
import { apiConnector } from '../../service/apiconnector';
import toast from 'react-hot-toast';
import Button from '../ui/Button-con'
import Sidebar from '../sidebar/Sidebar'

function Contact() {

    const { toast } = useToast()

    function changehandler(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value,


            }
        })
    }



    const [formData, setFormData] = useState({ name: '', email: '', number: '', message: '' })
    const { name, email, number, message } = formData;

    const [data, setData] = useState(
        [
            {
                question: "What services do you offer?",
                answer: "We specialize in web development, mobile app development, custom software solutions, UI/UX design, and digital strategy consulting.",
                open: false
            },
            {
                question: " Which industries do you serve?",
                answer: "We cater to diverse industries, including e-commerce, healthcare, finance, education, and more.",
                open: false
            },
            {
                question: "How experienced is your team?",
                answer: "Our highly skilled and experienced developers have a proven track record in delivering successful projects.",
                open: false
            },
            {
                question: "Can you handle small and large projects?",
                answer: "Yes, we can handle projects of all sizes, adapting to the unique needs of each client.",
                open: false
            },
            {
                question: "How do you ensure project security?",
                answer: "We prioritize security, implementing best practices, conducting regular audits, and adhering to strict confidentiality agreements.",
                open: false
            },

        ]




    )


    const submitHandler = async (event) => {
        try {

            event.preventDefault();

if(!name || !email || !number || !message){
    return toast({
        variant: "destructive",
        title: "Uh oh! Please Enter All Fields",
      })
}

            const result = await apiConnector("POST",'' + '/api/contact',  formData ).catch((error) => {
                console.log(error)
            })

            console.log(result)
            if(result.data.success){
                toast({
                    description: "Your message has been sent.",
                  })
            }
          
            setFormData({ name: '', email: '', number: '', message: '' })
        }
        catch (error) {
            console.log(error)
            toast({
                variant: "destructive",
                title: "Failed to Submit",
                
              })        }
    }
    const changehandle = (index) => {
        setData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                open: !updatedData[index].open,
            };
            return updatedData;
        });
    };


    useEffect(() => {
        console.log(data);
    }, [data]);


    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (
        <div className='max-w-screen mx-auto overflow-hidden px-2'>
            <div className="cursor-circle" style={{ left: position.x, top: position.y }}></div>
            <div className='absolute min-w-full left-[50%] translate-x-[-50%] z-50 '>

        <div className='flex pt-6  gap-2 justify-start ml-6 md:ml-0 md:justify-evenly items-center'>

          
          <div>
            <Icons.logo className='h-14 w-14' />

          </div>
          <div className='hidden md:flex '>
            <Navbar></Navbar>
          </div>


          <div className='md:flex hidden'>
            <Btn name='Get Started' />
          </div>



        </div>
      </div>
            
      <div className=' md:hidden'>
        <Sidebar />
      </div>

            <div>

                <div className=' font-light  mt-32 '>
                    <div className='flex flex-col justify-center gap-2 font-light items-center'>
                        <div className='text-richyellow-100 text-xl'>
                            GET IN TOUCH
                        </div>
                        <div className='text-richyellow-10 text-5xl'>
                            CONTACT US
                        </div>
                    </div>
                </div>
            </div>


            <div className='max-w-[80%] mx-auto mt-16 flex md:flex-row  flex-col justify-center items-center gap-20'>

                <div className='md:w-[400px] md:h-[400px] w-[300px] h-[300px]  bg-no-repeat bg-cover mx-4 bg-center rounded-[45px]' style={{ backgroundImage: `url(${img13}) ` }}  >

                </div>

                <div className='flex flex-col justify-center items-center gap-4'>
                    <div className='text-richyellow-10 '>

                        <div className='ml-4 text-lg mb-2 opacity-60'>
                            Name
                        </div>
                        <div>
                            <input type="text" name='name' value={name} className='md:w-96 w-72 h-12 rounded-md bg-richyellow-10 px-4 bg-opacity-10' placeholder='Name' onChange={changehandler} />

                        </div>

                    </div>
                    <div className='text-richyellow-10 '>

                        <div className='ml-4 text-lg mb-2 opacity-60'>
                            Email
                        </div>
                        <div>
                            <input type="text" name='email' value={email} className='md:w-96 w-72 h-12 rounded-md bg-richyellow-10 px-4 bg-opacity-10' placeholder='Email' onChange={changehandler} />
                        </div>

                    </div>
                    <div className='text-richyellow-10 '>

                        <div className='ml-4 text-lg mb-2 opacity-60'>
                            Phone Number
                        </div>
                        <div>
                            <input type="number" value={number} name='number' className='md:w-96 w-72 h-12 rounded-md bg-richyellow-10 number-input px-4 bg-opacity-10' placeholder='Phone Number' onChange={changehandler} />
                        </div>

                    </div>
                    <div className='text-richyellow-10 '>

                        <div className='ml-4 text-lg mb-2 opacity-60'>
                            Your Message
                        </div>
                        <div>
                            <textarea name="message" value={message} cols="3" rows="3" className='md:w-96 w-72  rounded-md bg-richyellow-10 px-4 bg-opacity-10' placeholder='Write Your Message Here' onChange={changehandler} ></textarea>
                        </div>

                    </div>

                    <Button >
                        <div className='w-32 h-10 rounded-full bg-richyellow-100 text-richblack-100 flex justify-center items-center text-xl cursor-pointer' onClick={submitHandler}>
                            <div>
                                Submit
                            </div>
                        </div>
                    </Button>

                </div>




            </div>



            <div className=' font-light  my-20'>
                <div className='flex flex-col justify-center gap-2  items-center'>

                    <div className='text-richyellow-10 text-5xl'>
                        FAQ's
                    </div>
                </div>
            </div>
            <div className='max-w-[80%] mx-auto'>
                {
                    data.map((faq, index) => {
                        return <div key={index} className='text-richyellow-10'>
                            <div className='bg-richyellow-10 bg-opacity-10 m-4 p-4 rounded-md flex items-center'>
                                <div className='min-w-[90%]'>
                                    <div className='text-2xl font-medium tracking-wider'>

                                        {faq.question}
                                    </div>

                                    <div className={`mt-2 text-lg font-light ${faq.open ? 'transition-all duration-700 ease-in-out max-h-[200vh]' : 'max-h-0 overflow-hidden transition-all duration-500 ease-in-out'}`}>
                                        {faq.answer}
                                    </div>

                                </div>
                                <div
                                    className='text-richyellow-10 min-w-[10%] text-3xl cursor-pointer'
                                    onClick={() => changehandle(index)}
                                >
                                    {
                                        faq.open ? <LuMinus /> : <LuPlus />

                                    }


                                </div>
                            </div>
                        </div>
                    })
                }
            </div>

            <div className='mt-20'>
                <Footer></Footer>
            </div>

        </div>
    )
}

export default Contact;