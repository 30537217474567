import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination , Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { FaArrowRight } from "react-icons/fa6";
import 'swiper/css/navigation';
import { useSwiper } from 'swiper/react';


 




function Reviews() {
  
 const swiper = useSwiper();
  const optionsData = [
    {
      image: "https://images.unsplash.com/photo-1531297484001-80022131f5a1?q=80&w=1420&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "John Doe",
      review: "truly understands our business needs and delivers beyond expectations. A reliable partner in our journey to success. "
    },
    {
      image: "https://images.unsplash.com/photo-1531297484001-80022131f5a1?q=80&w=1420&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "John Doe",
      review: "truly understands our business needs and delivers beyond expectations. A reliable partner in our journey to success."
    },
    {
      image: "https://images.unsplash.com/photo-1531297484001-80022131f5a1?q=80&w=1420&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "John Doe",
      review: "truly understands our business needs and delivers beyond expectations. A reliable partner in our journey to success."
    },
    {
      image: "https://images.unsplash.com/photo-1531297484001-80022131f5a1?q=80&w=1420&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: "John Doe",
      review: "truly understands our business needs and delivers beyond expectations. A reliable partner in our journey to success."
    },
  
    // Add other option objects here
  ];

  
  return (
    <div className='relative px-2'>
     <Swiper
        pagination={{
          type: 'fraction',
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >

        {
          optionsData.map((option, index) => {
            return <SwiperSlide key={index}>

              <div key={index} className=''>
                <div className='leading-loose tracking-wider flex-col  flex relative'>


                  <div className='absolute left-20 md:flex hidden'>
                    
                      <svg width="85" height="62" viewBox="0 0 85 62" fill="none" xmlns="http://www.w3.org/2000/svg" className='min-w-[10%]'>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2405 5.55022C21.7516 9.23417 12.9727 16.9337 10.7896 30.3332C10.1952 33.9779 11.0253 31.2252 13.5371 29.3758C21.7383 23.3361 37.8635 25.4746 40.1014 39.2678C44.6038 63.6897 13.5737 67.9176 4.38963 54.43C2.25299 51.2949 0.838531 47.6355 0.328857 43.6166C-1.88414 26.1379 7.21854 10.5705 23.2093 3.25816C26.1677 1.90547 29.2855 0.846851 32.6407 0.0692177C34.3207 -0.264053 35.541 0.634472 36.1038 2.15706C36.5802 3.45584 36.7429 4.88858 35.2405 5.55022ZM79.6964 5.55022C66.2075 9.23417 57.4269 16.9337 55.2455 30.3332C54.6511 33.9779 55.4812 31.2252 57.993 29.3758C66.1943 23.3361 82.3194 25.4746 84.5557 39.2678C89.0614 63.6897 58.0296 67.9176 48.8455 54.43C46.7089 51.2949 45.2944 47.6355 44.7848 43.6166C42.5718 26.1379 51.6744 10.5705 67.6652 3.25816C70.6236 1.90547 73.7397 0.846851 77.0966 0.0692177C78.7766 -0.264053 79.9969 0.634472 80.5597 2.15706C81.0361 3.45584 81.1988 4.88858 79.6964 5.55022Z" fill="white" fill-opacity="0.2" />
                  </svg>


                  </div>

                  <div className='flex flex-col gap-6 '>
                    <div>
                    <p className='text-richyellow-10  md:text-5xl sm:text-3xl   text-lg
                     flex items-center md:max-w-[70%] w-full mx-auto'>{`     ${option.review}`}

                      <p className='md:text-6xl sm:text-3xl   text-2xl text-richyellow-100 cursor-pointer' >
                        <FaArrowRight />
                      </p>


                    </p>
                    </div>
                   


                    <div className='flex  items-center ml-48 gap-2 '>
                      <div>
                        <img src={option.image} alt="" className='w-12 h-12 rounded-full border-[2px] border-richyellow-100' />
                      </div>

                      <div className='text-richyellow-10 md:text-lg text-sm font-light'>
                        {option.name}
                      </div>
                    </div>
                  </div>



                </div>

              </div>
            </SwiperSlide>
          })

        }

      </Swiper>
    </div>
  )
}

export default Reviews

