import React from 'react'
import { LuArrowUpRight } from "react-icons/lu";

function glass(props) {
  let { heading, para } = props;

  return (
    <div className='relative'>

      <svg width="600" height="170" viewBox="0 0 658 207" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_213_5)">
          <rect width="658" height="207" rx="45" fill="white" fill-opacity="0.1" />
          <rect x="1.5" y="1.5" width="655" height="204" rx="43.5" stroke="url(#paint0_linear_213_5)" stroke-opacity="0.29" stroke-width="3" />
        </g>
        <defs>
          <filter id="filter0_b_213_5" x="-24" y="-24" width="706" height="255" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_213_5" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_213_5" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_213_5" x1="1265.56" y1="128.926" x2="758.432" y2="-588.212" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="0.935041" stop-color="#0031A7" />
          </linearGradient>
        </defs>
      </svg>


      <div className='text-richyellow-10 absolute top-6 left-16 max-w-[80%] cursor-pointer'>
        <div className='md:text-3xl sm:text-xl text-lg font-semibold mb-4'>{heading}</div>
        <div className='md:text-lg sm:text-sm text-sm font-extralight'>
          {para}
        </div>

      </div>

      <div className='absolute text-6xl text-richyellow-10 top-4 right-10 cursor-pointer'><LuArrowUpRight /></div>

    </div>
  )
}

export default glass