import styles from './sidebar.scss';
import {Link} from 'react-router-dom';
import { motion } from 'framer-motion';
import { slide, scale } from './anim';

export default function Index() {
  
  
    return (

      <>
      
      <div className='flex flex-col gap-6 mt-36 w-full height-full justify-center items-center text-4xl '>


        <Link to='/'  >
        <div>
          Home
        </div>
        </Link>

        <Link to='/about'>
        <div>
          About Us
        </div>
        </Link>

        <Link to='/services'>
        <div>
          Services
        </div>
        </Link>

        <Link to='/projects'>
        <div>
          Projects
        </div>
        </Link>

        <Link to='/blogs'>
        <div>
          Blogs
        </div>
        </Link>

        <Link to='/contact'>
        <div>
          Contact
        </div>
        </Link>



      </div>


      </>
    )
}

   


      // <motion.div 
      //   className={styles.link} 
      //   onMouseEnter={() => {setSelectedIndicator(href)}} 
      //   custom={index} 
      //   variants={slide} 
      //   initial="initial" 
      //   animate="enter" 
      //   exit="exit"
      // >
      //   <motion.div 
      //     variants={scale} 
      //     animate={isActive ? "open" : "closed"} 
      //     className={styles.indicator}>
      //   </motion.div>
      //   <Link href={href}>{title}</Link>
      // </motion.div>