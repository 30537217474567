import React, { useEffect, useState } from 'react'
import Icons from "../logo"
import Btn from '../ui/btn'
import Footer from "../Footer"
import { BentoGrid, BentoGridItem } from "../ui/bento-grid";
import Sidebar from '../sidebar/Sidebar'

import Navbar from '../Navbar'
import { apiConnector } from '../../service/apiconnector';
function Blogs() {


  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiConnector("GET", 'http://localhost:1337/api/blogs?populate=*');
        console.log(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>

      <div className="cursor-circle" style={{ left: position.x, top: position.y }}></div>


      <div className='absolute min-w-full left-[50%] translate-x-[-50%] z-50 '>

        <div className='flex pt-6  gap-2 justify-start ml-6 md:ml-0 md:justify-evenly items-center'>


          <div>
            <Icons.logo className='h-14 w-14' />

          </div>
          <div className='hidden md:flex '>
            <Navbar></Navbar>
          </div>


          <div className='md:flex hidden'>
            <Btn name='Get Started' />
          </div>



        </div>
      </div>

      <div className=' md:hidden'>
        <Sidebar />
      </div>


      <div className='max-w-screen mx-auto pt-32 pb-20 px-2'>
        <div className=' font-light   '>
          <div className='flex flex-col justify-center gap-2 font-light items-center'>
            <div className='text-richyellow-100 text-3xl'>
              OUR
            </div>
            <div className='text-richyellow-10 text-5xl'>
              BLOGS
            </div>
          </div>
        </div>

        <BentoGrid className="max-w-[80%] mt-20 mx-auto">
          {items.map((item, i) => (
            <BentoGridItem
              key={i}
              title={item.title}
              description={item.description}
              header={item.header}
              className={i % 3 === 0 && i % 6 === 0 ? "md:col-span-2" : ""}
            />
          ))}
        </BentoGrid>

      </div>

      <Footer>

      </Footer>
    </div>
  )
}

export default Blogs

const items = [
  {
    title: "The Dawn of Innovation",
    description: "Explore the birth of groundbreaking ideas and inventions.",
    header: 'https://images.unsplash.com/photo-1620403724063-e7c763fe9d58?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },

  {
    title: "The Art of Design",
    description: "Discover the beauty of thoughtful and functional design.",
    header: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=1472&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: "The Power of Communication",
    description:
      "Understand the impact of effective communication in our lives.",
    header: 'https://images.unsplash.com/photo-1535223289827-42f1e9919769?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: "The Pursuit of Knowledge",
    description: "Join the quest for understanding and enlightenment.",
    header: 'https://images.unsplash.com/photo-1504610926078-a1611febcad3?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: "The Joy of Creation",
    description: "Experience the thrill of bringing ideas to life.",
    header: 'https://images.unsplash.com/photo-1484417894907-623942c8ee29?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    title: "The Spirit of Adventure",
    description: "Embark on exciting journeys and thrilling discoveries.",
    header: 'https://images.unsplash.com/photo-1531746790731-6c087fecd65a?q=80&w=1406&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  }
  ,
  {
    title: "The Spirit of Adventure",
    description: "Embark on exciting journeys and thrilling discoveries.",
    header: 'https://plus.unsplash.com/premium_photo-1677870728087-2257ce93bfe9?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  }


];