import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div>

            <div className='max-w-100% flex justify-between max-w-[90%] mt-32'>
                <div className='text-richyellow-10 max-w-[20%] gap-4 m-4'>
                    <div className='h-[2px] w-20 bg-richyellow-100 rounded-full mb-4'>

                    </div>
                    <div className='md:text-2xl sm:text-xl text-lg font-bold'>
                        WEBSPHERE
                    </div>
                    <div className='md:text-lg  text-xs font-extralight'>
                        Crafting Digital Dreams | Elevate Your Online Presence with Our Web Wizardry.


                    </div>
                </div>
                <div className='text-richyellow-10 max-w-[30%]'>

                    <ul className='flex flex-col gap-2 font-extralight items-center justify-center'>
                        <li className='font-medium md:text-lg  text-xs tracking-wider'>MAIN</li>
                        <li className='hover:text-richyellow-100 cursor-pointer'>Home</li>
                        <li className='hover:text-richyellow-100 cursor-pointer'>About</li>
                        <li className='hover:text-richyellow-100 cursor-pointer'>Services</li>
                        <li className='hover:text-richyellow-100 cursor-pointer'>Projects</li>
                    </ul>

                </div>


                <div className='text-richyellow-10 max-w-[30%]'>

                    <ul className='flex flex-col gap-2 font-extralight items-center justify-center'>
                        <li className='font-medium md:text-lg  text-xs tracking-wider'>PAGES</li>

                        <Link to='/'>
                            <li className='hover:text-richyellow-100 cursor-pointer'>Home</li>

                        </Link>
                        <Link to='/about'>
                            <li className='hover:text-richyellow-100 cursor-pointer'>About</li>

                        </Link>

                        <Link to='/services'>
                            <li className='hover:text-richyellow-100 cursor-pointer'>Services</li>

                        </Link>

                        <Link to='/projects'>
                            <li className='hover:text-richyellow-100 cursor-pointer'>Projects</li>

                        </Link>
                    </ul>

                </div>

            </div>

            <div className='h-[2px] w-[80%] mx-auto  bg-richyellow-100 rounded-full mt-10'>

            </div>
            <div className='flex gap-2 flex-wrap justify-center items-center text-sm text-richyellow-10 font-light h-20'>
                <div>
                    © 2024 Web Sphere. All rights reserved.
                </div>
                <div>
                    ph no. <span className='text-richyellow-100 p-2'><a href="tel:7877681196">+917877681196</a></span>
                    <a href='mailto:websphere40@gmail.com' className='text-richyellow-100'>websphere40@gmail.com</a>
                </div>

            </div>

            <div className='flex justify-center items-center text-sm text-richyellow-10 font-light mt-2 h-10 pb-10'>

                <div>
                    Developed and Designed by <Link to='https://www.linkedin.com/in/harsh-kandera-b22805238/' target='_blank'><span className='text-richyellow-100 cursor-pointer'>@HarshKandera</span></Link>
                </div>


            </div>
        </div>
    )
}

export default Footer