import React from 'react';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
const GoBackButton = (props) => {
  return (
    <Link to="/contact">
   
    <button 
    
      type="button"
      className="bg-white text-center w-44 rounded-2xl h-10 relative font-sans text-black text-lg font-semibold group"
    >
      <div
        className="bg-richyellow-100 rounded-xl h-8 w-1/4 flex items-center gap-2 justify-center absolute left-1 top-[4px] group-hover:w-[168px] z-10 duration-500"
      >

       <FaArrowRightLong />

      </div>
      <p className="translate-x-2">{props.name}</p>
    </button>
    </Link>
  );
};

export default GoBackButton;
