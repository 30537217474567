import React,{useEffect, useState} from 'react'
import Navbar from '../Navbar'

import Icons from "../logo"
import Btn from '../ui/btn'
import Footer from "../Footer"
import Sidebar from '../sidebar/Sidebar'


function Projects() {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

  return (
    
    <div className='max-w-screen mx-auto lg:overflow-visible overflow-hidden px-2'>


      <div className="cursor-circle" style={{ left: position.x, top: position.y }}></div>

      <div className='absolute min-w-full left-[50%] translate-x-[-50%] z-50 '>

<div className='flex pt-6  gap-2 justify-start ml-6 md:ml-0 md:justify-evenly items-center'>

  
  <div>
    <Icons.logo className='h-14 w-14' />

  </div>
  <div className='hidden md:flex '>
    <Navbar></Navbar>
  </div>


  <div className='md:flex hidden'>
    <Btn name='Get Started' />
  </div>



</div>
</div>
      

      <div className=' md:hidden'>
        <Sidebar />
      </div>

<div className='flex justify-center items-center h-screen w-screen'>
    <div className='text-white text-4xl font-bold'>
        Comming Soon !
    </div>
</div>


      </div>
  )
}

export default Projects