import React from 'react';
import './App.css';
import './app.scss';
import { Routes, Route } from 'react-router-dom';
import Home from "./components/pages/Home";
import SignIn from "./components/admin/sign-in";
import BlogWrite from "./components/admin/BlogWriting";
import ProtectedRoute from './components/admin/protected-route';
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Services from "./components/pages/Services";
import Blogs from "./components/pages/Blogs";
import Projects from './components/pages/Projects';
import Dashboard from "./components/admin/Dashboard"
function App() {
  return (
    <div className="max-w-screen mx-auto bg-richblack-100 font-inter min-h-screen">
      <Routes>
      <Route element={<ProtectedRoute />}>
      <Route path="/admin/write-blog" element={<BlogWrite />} />
      <Route path="/admin/dashboard" element={<Dashboard />} />
        </Route>


        <Route path="/admin/sign-in" element={<SignIn />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/projects" element={<Projects />} />

      </Routes>
    </div>
  );
}

export default App;
