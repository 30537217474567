import React from 'react'
import Navbar from "../Navbar"
import { IoIosArrowRoundForward } from "react-icons/io";
import img7 from "../../assets/img7.jpg"
import { LuArrowUpRight } from "react-icons/lu";
import Process from "../process"
import Glass from "../glass"
import Project from "../Projects"
import Reviews from '../Reviews';
import { useState, useEffect, useRef } from 'react';
import { BsArrowRight } from "react-icons/bs";
import Footer from "../Footer"
import Icons from "../logo"
import createGlobe from "cobe";
import { MaskContainer } from "../ui/svg-mask-effect";
import { TextReveal, WordFadeIn } from "../ui/text-reveal";
import Ripple from '../ui/ripple'
import NumberTicker from '../ui/number-ticker';
import { TracingBeam } from "../ui/tracing-beam";
import { HeroParallax } from '../ui/hero-parallax';
import Btn from '../ui/btn';
import img5 from "../../assets/bluegr.png"
import img4 from "../../assets/img4.jpg"
import Sidebar from '../sidebar/Sidebar'
export const products = [
  {
    title: "Moonbeam",
    link: "https://gomoonbeam.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/moonbeam.png",
  },
  {
    title: "Cursor",
    link: "https://cursor.so",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/cursor.png",
  },
  {
    title: "Rogue",
    link: "https://userogue.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/rogue.png",
  },

  {
    title: "Editorially",
    link: "https://editorially.org",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/editorially.png",
  },
  {
    title: "Editrix AI",
    link: "https://editrix.ai",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/editrix.png",
  },
  {
    title: "Pixel Perfect",
    link: "https://app.pixelperfect.quest",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/pixelperfect.png",
  },

  {
    title: "Algochurn",
    link: "https://algochurn.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/algochurn.png",
  },
  {
    title: "Aceternity UI",
    link: "https://ui.aceternity.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/aceternityui.png",
  },
  {
    title: "Tailwind Master Kit",
    link: "https://tailwindmasterkit.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/tailwindmasterkit.png",
  },
  {
    title: "SmartBridge",
    link: "https://smartbridgetech.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/smartbridge.png",
  },
  {
    title: "Renderwork Studio",
    link: "https://renderwork.studio",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/renderwork.png",
  },

  {
    title: "Creme Digital",
    link: "https://cremedigital.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/cremedigital.png",
  },
  {
    title: "Golden Bells Academy",
    link: "https://goldenbellsacademy.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/goldenbellsacademy.png",
  },
  {
    title: "Invoker Labs",
    link: "https://invoker.lol",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/invoker.png",
  },
  {
    title: "E Free Invoice",
    link: "https://efreeinvoice.com",
    thumbnail:
      "https://aceternity.com/images/products/thumbnails/new/efreeinvoice.png",
  },
];

function Home() {

  const steps = [
    {
      step: "01",
      title: "Information Collection",
      description: "Think of this as unearthing a diamond in the rough. Through interviews, surveys, and competitor analyses, you gather crucial intel about the client's business, target audience, and website aspirations. What are their goals? Who are they trying to reach? The deeper you dig, the clearer the picture of the website's ideal form becomes."
    },
    {
      step: "02",
      title: "Wireframe",
      description: "Now, it's time to transform the rough stone into a stunning design. Wireframing acts as the blueprint, outlining the website's structure, pages, and key elements like navigation, content sections, and calls to action. This stage prioritizes user flow and functionality, ensuring visitors can find what they need effortlessly."
    },
    {
      step: "03",
      title: "UI/UX Design",
      description: "This is where the magic truly happens! UI/UX design marries visual aesthetics with intuitive user experience. Think captivating color palettes, engaging fonts, and interactive elements that resonate with the client's brand and target audience. Remember, beauty without brains is useless – prioritize usability and accessibility alongside those eye-catching visuals."
    },
    {
      step: "04",
      title: "UI/UX Design",
      description: "The final step is where the website comes alive! Skilled developers translate your designs into functional code, building the front-end interface users see and interact with, along with the robust back-end that powers it all. Rigorous testing and quality assurance ensure every pixel is polished before launch."
    }
  ];

  const canvasRef = useRef();

  useEffect(() => {
    let phi = 0;

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 600 * 2,
      height: 600 * 2,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.1, 0.8, 1],
      glowColor: [1, 1, 1],
      markers: [
        // longitude latitude
        { location: [37.7595, -122.4367], size: 0.03 },
        { location: [40.7128, -74.006], size: 0.1 }
      ],
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi;
        phi += 0.01;
      }
    });

    return () => {
      globe.destroy();
    };
  }, []);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  const data = {
    experience: "5",
    team: "12",
    customers: "120"
  }





  return (


    <div className='max-w-screen mx-auto lg:overflow-visible overflow-hidden px-2'>


      <div className="cursor-circle" style={{ left: position.x, top: position.y }}></div>

      <div className='absolute min-w-full left-[50%] translate-x-[-50%] z-50 '>

        <div className='flex pt-6  gap-2 justify-start ml-6 md:ml-0 md:justify-evenly items-center'>

          
          <div>
            <Icons.logo className='h-14 w-14' />

          </div>
          <div className='hidden md:flex '>
            <Navbar></Navbar>
          </div>


          <div className='md:flex hidden'>
            <Btn name='Get Started' />
          </div>



        </div>
      </div>


      <div className=' md:hidden'>
        <Sidebar />
      </div>






      <div className='h-screen z-20  flex justify-center items-center   mx-auto'>


        <Ripple className='max-w-screen mx-auto overflow-hidden' />


        <div >


          <div className='flex flex-col  justify-center items-center gap-6'>

            <div className=' flex flex-col justify-center items-center text-richyellow-10 md:max-w-[60%] '>

              <div className=' md:text-8xl sm:text-7xl text-5xl  font-bold tracking-wider'>
                UNLEASH
              </div>
              <div className='md:text-8xl sm:text-7xl text-5xl   font-bold   tracking-wider '>
                DIGITAL
              </div>
              <div className='md:text-8xl sm:text-7xl text-5xl  text-richyellow-10 font-bold tracking-wider'>
                POTENTIAL
              </div>

              <div className='sm:text-lg text-xs text-center px-2 text-richyellow-10 mt-8 '>
                Beautiful design has the power to captivate audience WEBSPHERE specializes in creating stunning designs.
              </div>


            </div>

            <div className='md:hidden flex'>
              <Btn name='Get Started' />
            </div>

          </div>



        </div>


      </div>







      <div className='flex justify-center mt-10  items-center tracking-wider '>

        <div className='text-richyellow-10 flex flex-col justify-center items-center lg:w-[60%] px-2 w-full '>
          <div className='md:text-5xl sm:text-4xl text-3xl font-semibold m-4'>
            We <span className='text-richyellow-100'>are</span> Websphere
          </div>


          <MaskContainer
            revealText={

              <p className="max-w-4xl mx-auto text-white/30 text-center md:text-3xl sm:text-xl text-sm sm:leading-9 font-medium">
                Fueling Growth, Sparking Innovation: Where <br /> Digital Dreams Become Reality. We don't just  design and develop; we craft success  stories,  identifying goals, and weaving impactful, realistic <br className='md:block hidden' /> solutions together .
              </p>

            }
            className="h-[15rem] w-full  rounded-md"
          >
            Fueling <span className="text-red-500">Growth</span>, Sparking <span className="text-red-500">Innovation</span>: Where <br /> Digital Dreams Become <span className="text-red-500">Reality</span>. We don't just design and develop; we craft <span className="text-red-500">success stories</span>, identifying <span className="text-red-500">goals</span>, and weaving impactful, realistic  <br className='md:block hidden' /> <span className="text-red-500">solutions</span> together.

          </MaskContainer>

        </div>
      </div>






      <div className='mt-28 px-2' >


        <div className='hidden lg:flex flex-1 justify-center items-center'>
          <TextReveal text='Empowering Your Success  By Tackling User Challenges  to Achieve Your Business Goals Together' />
        </div>




        <div className='flex lg:hidden' >



          <div
            className={
              " mx-auto flex p-2 relative md:pl-6 max-w-4xl md:flex-row  flex-col items-center justify-center  bg-[#D9D9D9] rounded-[45px] bg-opacity-10 backdrop-blur-sm	"
            }
          >
            
            <div className='w-full   relative top-0 '>

              <img src={img4} alt="" className='rounded-[45px] w-full h-full object-cover' />

            </div>


            <div>
              <p
                className={
                  "flex flex-wrap p-5 text-2xl font-bold text-white dark:text-white/30 md:text-3xl  lg:text-4xl xl:text-5xl"
                }
              >
                <WordFadeIn words="Empowering Your Success By Tackling User Challenges to Achieve Your Business Goals Together " />

              </p>
            </div>

            <div className='absolute top-0 right-0 w-[500px] z-0 '>
              <img src={img5} alt="" />
            </div>

     

          </div>


        </div>






      </div>



      <div className='pt-10 overflow-hidden'>
        <div className=' flex flex-col justify-center positionCenter items-center mx-auto mt-48 relative '>

          <div className='text-richyellow-10  md:text-6xl sm:text-5xl text-3xl font-black tracking-widest z-50'>
            CREATING PROCESS
          </div>

          <div className='text-richyellow-10 md:text-xl sm:text-lg text-sm tracking-widest max-w-[90%] sm:max-w-[70%] text-center mt-10 font-light leading-relaxed z-50'>
            STEP BY STEP PROCESS OF CREATING TEMPLATE TO THE FINISHED FINAL PRODUCT AND ACTIVE WEBSITE
          </div>



          <div className='orange  absolute top-[-100px] left-36 z-0'>

          </div>



          <div className='orange-2 absolute right-0 bottom-96 z-0'></div>


          <div className='flex md:hidden'>

            <TracingBeam className="mt-10 z-10">

              <div className='grid grid-cols-1  md:max-w-[80%] w-full  mx-auto gap-4 px-8 text-white items-center'>
                {steps.map((step) => (

                  <div key={step.step} className='flex bg-[#D9D9D9] bg-opacity-10 p-6 max-w-[480px] border-2 border-gray-500 rounded-lg flex-col justify-center  gap-2'>
                    {/* <BorderBeam size={250} duration={12} delay={9} /> */}
                    <div className='flex text-lg gap-2 items-center justify-center'>
                      <div>
                        {step.step}
                      </div>
                      <div>
                        {step.title}
                      </div>
                    </div>

                    <div className='text-sm text-gray-300'>
                      {step.description}
                    </div>

                  </div>

                ))}


              </div>

            </TracingBeam>
          </div>


          <div className='mt-28 hidden md:flex z-50'>
            <Process></Process>
          </div>


          <div className='yellow z-0 hidden md:flex absolute left-[-100px] bottom-[-20px]'>

          </div>

          <div className='yellow-2 flex md:hidden absolute left-[-100px] bottom-[-20px]'>

          </div>

        </div>
      </div>



      <div className=' font-light  mt-28 '>


        <div className='flex flex-col justify-center gap-2 font-light items-center'>
          <div className='text-richyellow-100 text-3xl'>
            OUR
          </div>
          <div className='text-richyellow-10 text-5xl'>
            SERVICES
          </div>
        </div>
      </div>

      <div className='flex mt-20 md:flex-row  flex-col justify-center items-center relative'>


        <div className='px-4' >
          <img src={img7} alt="" className='w-[440px] rounded-[45px] object-cover' />
        </div>

        <div className='text-white max-w-[90%] z-10  md:hidden flex flex-col gap-4 mt-10'>

          <div className='flex items-center rounded-lg p-4 bg-[#D9D9D9] bg-opacity-10'>

            <div className='flex  flex-col gap-4 justify-center'>

              <div className='text-xl font-medium'>
                Design
              </div>
              <div className='text-sm'>
                Elevate your brand with captivating design that speaks volumes effortlessly.
              </div>

            </div>


            <div className='text-4xl'>
              <LuArrowUpRight />
            </div>


          </div>

          <div className='flex items-center rounded-lg p-4 bg-[#D9D9D9] bg-opacity-10'>

            <div className='flex  flex-col gap-4 justify-center'>

              <div className='text-xl font-medium'>
                Web Development
              </div>
              <div className='text-sm'>
                transforming visions into digital success stories.
              </div>

            </div>


            <div className='text-4xl'>
              <LuArrowUpRight />
            </div>


          </div>

          <div className='flex items-center rounded-lg p-4 bg-[#D9D9D9] bg-opacity-10'>

            <div className='flex  flex-col gap-4 justify-center'>

              <div className='text-xl font-medium'>
                Seo Services
              </div>
              <div className='text-sm'>
                Skyrocket online visibility: strategic SEO, precise keywords, analytics-fueled growth.  </div>

            </div>


            <div className='text-4xl'>
              <LuArrowUpRight />
            </div>


          </div>


        </div>


        <div className='flex gap-2 hidden md:flex flex-col z-50'>]
          <Glass heading="Design" para="Elevate your brand with captivating design that speaks volumes effortlessly.">
          </Glass>
          <Glass heading="Web Development" para="transforming visions into digital success stories.">
          </Glass>
          <Glass heading="Seo Services" para="Skyrocket online visibility: strategic SEO, precise keywords, analytics-fueled growth.">
          </Glass>
        </div>

        <div className='bluegr  absolute right-[-50px] z-0'>

        </div>


      </div>



      <div className=' font-light   mt-28 '>
        <div className='flex flex-col justify-center gap-2 font-light items-center'>
          <div className='text-richyellow-100 text-3xl'>
            OUR
          </div>
          <div className='text-richyellow-10 text-5xl'>
            PROJECTS
          </div>
        </div>
      </div>

      <div className=' mt-10'>
        {/* <Project></Project> */}
        <HeroParallax products={products} />
      </div>

      <div className=' font-light  mt-28 '>
        <div className='flex flex-col justify-center gap-2 font-light items-center'>
          <div className='text-richyellow-100 text-3xl'>
            OUR
          </div>
          <div className='text-richyellow-10 text-5xl'>
            CUSTOMERS
          </div>
        </div>

        <div className='mx-auto  mt-20 pb-20 h-full m'>
          <Reviews></Reviews>
        </div>
      </div>


      <div className='flex flex-col gap-10 justify-center items-center mt-28'>

        <div className='h-2 w-20 bg-richyellow-100 rounded-full'>

        </div>


        <div className='flex flex-col md:flex-row gap-28 max-w-[80%] mx-auto'>

          <div className='flex items-center gap-2'>
            <div className='text-richyellow-100  md:text-7xl sm:text-5xl text-3xl flex items-center font-bold'>

              <NumberTicker value={data.experience} />

              <span className=' md:text-6xl sm:text-5xl text-3xl  font-bold'>+</span>
            </div>

            <div className='text-richyellow-100 font-extralight text-2xl'>
              YEARS OF <br /> EXPERIENCE
            </div>
            <div>

            </div>
          </div>

          <div className='flex items-center gap-2 '>

            <div className='text-richyellow-100  md:text-7xl sm:text-5xl text-3xl flex items-center stroke2  font-bold'>
              <NumberTicker value={data.team} />


              <span className='md:text-6xl sm:text-5xl text-3xl  font-bold'><svg width="30" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-outside-1_242_2" maskUnits="userSpaceOnUse" x="-2" y="-2" width="53" height="54" fill="black">
                  <rect fill="white" x="-2" y="-2" width="53" height="54" />
                  <path d="M18.5227 49.2159V0.943178H30.7273V49.2159H18.5227ZM0.488636 31.1818V18.9773H48.7614V31.1818H0.488636Z" />
                </mask>
                <path d="M18.5227 49.2159H16.5227V51.2159H18.5227V49.2159ZM18.5227 0.943178V-1.05682H16.5227V0.943178H18.5227ZM30.7273 0.943178H32.7273V-1.05682H30.7273V0.943178ZM30.7273 49.2159V51.2159H32.7273V49.2159H30.7273ZM0.488636 31.1818H-1.51136V33.1818H0.488636V31.1818ZM0.488636 18.9773V16.9773H-1.51136V18.9773H0.488636ZM48.7614 18.9773H50.7614V16.9773H48.7614V18.9773ZM48.7614 31.1818V33.1818H50.7614V31.1818H48.7614ZM20.5227 49.2159V0.943178H16.5227V49.2159H20.5227ZM18.5227 2.94318H30.7273V-1.05682H18.5227V2.94318ZM28.7273 0.943178V49.2159H32.7273V0.943178H28.7273ZM30.7273 47.2159H18.5227V51.2159H30.7273V47.2159ZM2.48864 31.1818V18.9773H-1.51136V31.1818H2.48864ZM0.488636 20.9773H48.7614V16.9773H0.488636V20.9773ZM46.7614 18.9773V31.1818H50.7614V18.9773H46.7614ZM48.7614 29.1818H0.488636V33.1818H48.7614V29.1818Z" fill="#C5FB67" mask="url(#path-1-outside-1_242_2)" />
              </svg>
              </span>
            </div>

            <div className='text-richyellow-100 font-extralight text-2xl'>
              OUR TEAM<br /> MEMBERS
            </div>



          </div>


          <div className='flex items-center gap-2'>
            <div className='text-richyellow-100 md:text-7xl sm:text-5xl text-3xl flex items-center font-bold'>

              <NumberTicker value={data.customers} />

              <span className='md:text-6xl sm:text-5xl text-3xl  font-bold'>+</span>
            </div>

            <div className='text-richyellow-100 font-extralight text-2xl'>
              OUR HAPPY <br /> CUSTOMERS
            </div>
            <div>

            </div>
          </div>


        </div>

        <div className='h-2 w-20 bg-richyellow-100 rounded-full'>

        </div>


      </div>


      <div className='mt-32 pl-2'>
        <div className='text-3xl text-richyellow-10 font-light '>
          OUR VALUED
        </div>
        <div className='text-5xl text-richyellow-10 font-medium'>
          International <br /> Clients .
        </div>
      </div>

      <div className='h-screen flex justify-center items-center'>
        <canvas
          ref={canvasRef}
          style={{ width: 600, height: 600, maxWidth: "100%", aspectRatio: 1 }}
        />
      </div>



      <div className='flex flex-col justify-center my-20 font-light items-center'>
        <div className='text-richyellow-100 text-3xl'>
          CONTACT US
        </div>

      </div>

      <div className='relative'>
        <div className='whitegr absolute z-0'>

        </div>
        <div className='flex flex-col justify-center items-center leading-relaxed tracking-wider z-50'>
          <div className=' md:text-9xl sm:text-7xl text-5xl text-richyellow-10'>
            LET'S GET
          </div>
          <div className='md:text-9xl sm:text-7xl text-5xl text-richyellow-10 stroke3'>
            STARTED
          </div>

          {/* <div className='w-48 h-12 mt-20 rounded-full bg-richyellow-100 text-richblack-200 flex justify-center items-center cursor-pointer'>
            <div className='flex justify-center items-center gap-2'>
              <div>
                GET IN TOUCH
              </div>
              <div>
                <BsArrowRight />

              </div>
            </div>
          </div> */}
          <div className='mt-20 '>
            <Btn name="Get Started" />
          </div>
        </div>


      </div>

      <Footer></Footer>
    </div>


  )
}

export default Home
