import React from 'react'
import { Button } from '../ui/Button'
import Icons from "../logo"
import Img19 from '../../assets/img19.png'
import { Link } from 'react-router-dom'
import { useState,useEffect } from 'react'
import { apiConnector } from '../../service/apiconnector';

function Dashboard () {

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Define the async function inside useEffect
    const fetchBlogs = async () => {
      try {
        const result = await apiConnector("GET", '' + 'api/get_blogs');
        
        // // Check if the result contains data and update state
        // if (result.data) {
        //   setBlogs(result.data);
        // }
        
        console.log(result);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    // Call the async function
    fetchBlogs();
  }, []); // Empty dependency array to run only once



  return (

    <div className='max-w-screen mx-auto min-h-screen px-20 bg-white'>

      <div className=' mt-16 '>

        <div className='flex gap-10 '>
          <Link to='/admin/dashboard'>
          <Button variant="secondary" className='rounded-full '>
            Blogs
          </Button>
          </Link>
        

          <Link to='/admin/write-blog'>
            <Button variant="secondary">
              New Blogs
            </Button>
          </Link>

          <Link>
            <Button variant="secondary">
              Contact
            </Button>
          </Link>

        </div>
      </div>
   
      {
        <div className='h-screen w-full flex flex-col justify-center items-center'>
          <img src={Img19} className='w-72' />

          <div className='text-gray-500 '>
            No Blog Found, Start Posting Now !
          </div>

        </div>
      }

    </div>
  )
}

export default Dashboard; 